import React from "react";
import Layout from "../../components/layout";
import CloudSolutions from "../../components/views/solutions/cloud-solutions";

const CloudSolutionsPage = () => {
  return (
    <Layout>
      <CloudSolutions />
    </Layout>
  );
};

export default CloudSolutionsPage;
